import React from "react"
import ReactModal from "react-modal"
import styled, { createGlobalStyle, css } from "styled-components"
import { CloseIcon } from "../icons/icons"
import { breakpoints, ThemeProvider } from "../styles"
import {
  SPACE_4XL,
  SPACE_M,
  SPACE_XL,
  SPACE_1XL,
  COLOR_GRAY_900_RGBA,
} from "../styles/primitives"
import { Text } from "./Text"
import { Image } from "./Image"
import { CTAButton } from "./CTAButton"
import { useEffect } from "react"
import { HubspotFormSlice } from "./slices/HubspotFormSlice"
import { RichText } from "./RichText"

const ReactModalGlobalStyles = createGlobalStyle`
  .ReactModal__Overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${COLOR_GRAY_900_RGBA};
    z-index: 100;
  }

  .ReactModal__Body--open{
    overflow:hidden;
  }
`

const CloseIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: ${SPACE_XL / 2}px;
  right: ${SPACE_XL / 2}px;

  svg {
    width: 12px;
    height: 12px;

    .colored-fg {
      fill: ${({ theme }) => theme.textColor};
    }

    &:hover {
      .colored-fg {
        fill: ${({ theme }) => theme.hoverTextColor};
      }
    }
  }
`

const CloseIconOnImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${SPACE_XL / 2}px;
  right: ${SPACE_XL / 2}px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: 50%;
  padding: 6px;

  .colored-fg {
    fill: ${({ theme }) => theme.textColor};
  }
  
  &:hover {
    border: 1px solid;
    border-color: ${({ theme }) => theme.backgroundColor};
    background-color: transparent;   
    transition: all 1s ease-out;

    .colored-fg {
      fill: ${({ theme }) => theme.backgroundColor};
    }
  }

  svg {
    width: 12px;
    height: 12px;  
    }
  }
`

const HeadlineText = styled(Text)`
  grid-row-start: headline-start;
  grid-row-end: headline-end;
  grid-column-start: content-start;
  grid-column-end: content-end;
  text-align: center;
`

const ChildrenContainer = styled.div`
  grid-row-start: content-start;
  grid-row-end: content-end;
  grid-column-start: content-start;
  grid-column-end: content-end;
`

export function Modal({
  headline,
  modalStyle,
  theme,
  countdown,
  image,
  popUpCta,
  children,
  onClose,
  minWidth,
  maxWidth,
  topMargin,
  bottomMargin,
  hubspotForm,
  ...otherProps
}) {
  let myInterval = null

  useEffect(() => {
    if (!countdown) return

    if (myInterval != null) return

    myCountdown()

    return () => {
      if (myInterval) {
        clearInterval(myInterval)
      }
    }
  }, [countdown])

  function myCountdown() {
    if (modalStyle != "pictureTextCountdown") {
      return
    }

    var targetDate = new Date(countdown)

    myInterval = setInterval(() => {
      var now = new Date()
      var diff = targetDate.getTime() - now.getTime()
      var h = Math.floor(diff / (1000 * 60 * 60))
      var m = Math.floor((diff / (1000 * 60)) % 60)
      var s = Math.floor(diff / 1000) % 60

      if (h <= 9) {
        h = "0" + h
      }
      if (m <= 9) {
        m = "0" + m
      }
      if (s <= 9) {
        s = "0" + s
      }

      const secondsHTML = document.getElementById("seconds")
      secondsHTML.innerHTML = s

      const minutesHTML = document.getElementById("minutes")
      minutesHTML.innerHTML = m

      const hoursHTML = document.getElementById("hours")
      hoursHTML.innerHTML = h
    }, 1000)
  }

  const StyledReactModal = styled(ReactModal)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: ${minWidth ?? "300"}px;
    max-width: ${maxWidth ?? "500"}px;

    border-radius: 24px;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    outline: none;
  `

  const ContentContainer = styled.div`
    display: grid;
    ${breakpoints({
      SM: css`
        grid-template-rows: [top] ${topMargin ?? 80 - SPACE_XL - 10}px [headline-start] min-content [headline-end] ${56}px [content-start] auto [content-end] ${bottomMargin ??
          80 - SPACE_XL - 10}px [bottom];
        grid-template-columns: [left] ${SPACE_1XL}px [content-start] auto [content-end] ${SPACE_1XL}px [right];
      `,
      MD: css`
        grid-template-rows: [top] ${topMargin ?? SPACE_4XL - SPACE_XL - 10}px [headline-start] min-content [headline-end] ${56}px [content-start] auto [content-end] ${bottomMargin ??
          SPACE_4XL - SPACE_XL - 10}px [bottom];
        grid-template-columns: [left] ${SPACE_1XL}px [content-start] auto [content-end] ${SPACE_1XL}px [right];
      `,
      LG: css`
        grid-template-rows: [top] ${topMargin ?? SPACE_4XL - SPACE_XL - 10}px [headline-start] min-content [headline-end] ${56}px [content-start] auto [content-end] ${bottomMargin ??
          SPACE_4XL - SPACE_XL - 10}px [bottom];
        grid-template-columns: [left] ${SPACE_1XL}px [content-start] auto [content-end] ${SPACE_1XL}px [right];
      `,
      XL: css`
        grid-template-rows: [top] ${topMargin ?? SPACE_4XL - SPACE_XL - 10}px [headline-start] min-content [headline-end] ${56}px [content-start] auto [content-end] ${bottomMargin ??
          SPACE_4XL - SPACE_XL - 10}px [bottom];
        grid-template-columns: [left] ${SPACE_1XL}px [content-start] auto [content-end] ${SPACE_1XL}px [right];
      `,
    })};
  `

  const ModalContainer = styled.div`
    display: grid;
    grid-template-rows: [top] ${SPACE_1XL}px [content-start] auto [content-end] ${SPACE_1XL}px [bottom];
    grid-template-columns: [left] ${SPACE_1XL}px [content-start] auto [content-end] ${SPACE_1XL}px [right];
  `

  const StyledReactModalExtendedPopUp = styled(ReactModal)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    ${breakpoints({
      SM: css`
        width: 90%;
      `,
      MD: css`
        width: 620px;
      `,
      LG: css`
        width: 720px;
      `,
      XL: css`
        width: 820px;
      `,
    })}

    border-radius: 24px;
    background-color: ${({ theme }) => theme.backgroundColor};
    outline: none;
  `

  const SimplePictureContainer = styled.div`
    display: flex;
    flex-direction: column;
  `

  const SimplePictureChildrenContainer = styled.div`
    display: flex;
    padding: ${SPACE_XL / 2}px;
    text-align: right;
    font-size: 14px;
    color: ${({ theme }) => theme.textColor};

    ${breakpoints({
      XL: css`
        font-size: 16px;
      `,
    })};
  `

  const PictureTextCTAContainer = styled.div`
    display: flex;
    ${breakpoints({
      SM: css`
        flex-direction: column;
      `,
      MD: css`
        flex-direction: column;
      `,
    })};
  `

  const PictureTextCountdownContainer = styled.div`
    display: flex;
    flex-direction: column;
  `

  const PictureTextCountdownContent = styled.div`
    display: flex;
    justify-content: center;
    flex-shrink: 0;
  `

  const PictureTextCountdownCountdownContainer = styled.div`
    display: flex;
    justify-content: space-around;
    color: ${({ theme }) => theme.backgroundColor};
    position: absolute;
    top: calc(50% - 90px);
    width: 100%;
    text-align: center;

    div {
      width: 30%;
    }

    .colon {
      width: 5%;
    }

    font-weight: 800;

    ${breakpoints({
      SM: css`
        font-size: 72px;
        top: calc(50% - 72px);
      `,
      MD: css`
        font-size: 96px;
      `,
      LG: css`
        font-size: 120px;
      `,
      XL: css`
        font-size: 144px;
      `,
    })}
  `

  const PictureTextCountdownChildrenContainer = styled.div`
    display: flex;
    padding: ${SPACE_XL / 2}px;
    text-align: center;
    font-size: 14px;
    color: ${({ theme }) => theme.textColor};

    ${breakpoints({
      XL: css`
        font-size: 16px;
      `,
    })};
  `

  const PictureTextCTAContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 33%;

    flex-shrink: 0;
    ${breakpoints({
      SM: css`
        width: 100%;
        flex-direction: column;
        align-items: center;
      `,
      MD: css`
        width: 100%;
        flex-direction: column;
        align-items: center;
      `,
    })};
  `

  const PictureTextCTAChildrenContainer = styled.div`
    display: flex;
    padding: ${SPACE_XL / 2}px;
    text-align: right;
    font-size: 14px;
    color: ${({ theme }) => theme.textColor};

    ${breakpoints({
      SM: css`
        text-align: center;
      `,
      MD: css`
        text-align: center;
      `,
      XL: css`
        font-size: 16px;
      `,
    })};
  `

  const PictureTextCTAButtonContainer = styled.div`
    display: flex;
    padding: ${SPACE_M}px;
    justify-content: flex-end;
    align-items: center;

    ${breakpoints({
      SM: css`
        padding-top: 0;
      `,
      MD: css`
        a {
          min-width: 80% !important;
        }
        button {
          width: 100% !important;
          height: 40px;
        }
        padding-top: 0;
      `,
      LG: css`
        a {
          min-width: 80% !important;
        }
        button {
          width: 100% !important;
          height: 40px;
        }
      `,
      XL: css`
        a {
          min-width: 80% !important;
        }
        button {
          width: 100% !important;
          height: 40px;
        }
      `,
    })};
  `

  if (modalStyle === "pictureTextCTA") {
    return (
      <ThemeProvider themeName={theme}>
        <ReactModalGlobalStyles />
        <StyledReactModalExtendedPopUp
          isOpen={true}
          overlayClassName="ReactModal__Overlay"
          onRequestClose={onClose}
        >
          <PictureTextCTAContainer>
            <Image asset={image} />
            <PictureTextCTAContent>
              <CloseIconContainer onClick={onClose} color={theme.iconColor}>
                <CloseIcon />
              </CloseIconContainer>
              <PictureTextCTAChildrenContainer>
                {children}
              </PictureTextCTAChildrenContainer>
              <PictureTextCTAButtonContainer>
                <CTAButton cta={popUpCta} />
              </PictureTextCTAButtonContainer>
            </PictureTextCTAContent>
          </PictureTextCTAContainer>
        </StyledReactModalExtendedPopUp>
      </ThemeProvider>
    )
  } else if (modalStyle === "pictureTextCountdown") {
    return (
      <ThemeProvider themeName={theme}>
        <ReactModalGlobalStyles />
        <StyledReactModalExtendedPopUp
          isOpen={true}
          overlayClassName="ReactModal__Overlay"
          onRequestClose={onClose}
        >
          <PictureTextCountdownContainer>
            <Image asset={image} />
            <PictureTextCountdownCountdownContainer>
              <div id="hours"></div>
              <div className="colon">:</div>
              <div id="minutes"></div>
              <div className="colon">:</div>
              <div id="seconds"></div>
            </PictureTextCountdownCountdownContainer>
            <PictureTextCountdownContent>
              <CloseIconOnImageContainer
                onClick={onClose}
                color={theme.iconColor}
              >
                <CloseIcon />
              </CloseIconOnImageContainer>

              <PictureTextCountdownChildrenContainer>
                {children}
              </PictureTextCountdownChildrenContainer>
            </PictureTextCountdownContent>
          </PictureTextCountdownContainer>
        </StyledReactModalExtendedPopUp>
      </ThemeProvider>
    )
  } else if (modalStyle === "simplePicture") {
    return (
      <ThemeProvider themeName={theme}>
        <ReactModalGlobalStyles />
        <StyledReactModalExtendedPopUp
          isOpen={true}
          overlayClassName="ReactModal__Overlay"
          onRequestClose={onClose}
        >
          <SimplePictureContainer>
            <Image asset={image} />
            <CloseIconOnImageContainer
              onClick={onClose}
              color={theme.iconColor}
            >
              <CloseIcon />
            </CloseIconOnImageContainer>
            {children !== null && (
              <SimplePictureChildrenContainer>
                {children}
              </SimplePictureChildrenContainer>
            )}
          </SimplePictureContainer>
        </StyledReactModalExtendedPopUp>
      </ThemeProvider>
    )
  } else if (modalStyle === "hubspotForm") {
    return (
      <ThemeProvider themeName={theme}>
        <ReactModalGlobalStyles />
        <StyledReactModal
          isOpen={true}
          overlayClassName="ReactModal__Overlay"
          onRequestClose={onClose}
        >
          <CloseIconContainer onClick={onClose}>
            <CloseIcon />
          </CloseIconContainer>
          <ContentContainer>
            <HeadlineText type="secondary">{headline}</HeadlineText>
            <ChildrenContainer>
              {children}
              <HubspotFormSlice slice={hubspotForm} padding={false} />
            </ChildrenContainer>
          </ContentContainer>
        </StyledReactModal>
      </ThemeProvider>
    )
  } else {
    return (
      <ThemeProvider themeName={theme}>
        <ReactModalGlobalStyles />
        <StyledReactModal
          isOpen={true}
          overlayClassName="ReactModal__Overlay"
          onRequestClose={onClose}
        >
          <CloseIconContainer onClick={onClose}>
            <CloseIcon />
          </CloseIconContainer>
          <ContentContainer>
            <HeadlineText type="secondary">
              {headline} {modalStyle}
              {theme}
            </HeadlineText>
            <ChildrenContainer>{children}</ChildrenContainer>
          </ContentContainer>
        </StyledReactModal>
      </ThemeProvider>
    )
  }
}
