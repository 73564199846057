import React, { useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import {
  breakpoints,
  TEXT_TOKEN_COPY_BREAKPOINT_MD,
  ThemeProvider,
} from "../../styles"
import { RichText } from "../RichText"
import { Text, textConfigToCSS } from "../Text"
import { BREAKPOINT_SM } from "../../styles/breakpoints"
import {
  COLOR_BLUE,
  COLOR_CORAL,
  COLOR_GRAY_100,
  COLOR_GREEN,
  SPACE_L,
  SPACE_XL,
  SPACE_1XL,
  SPACE_2XL,
  SPACE_3XL,
} from "../../styles/primitives"
import { getSliceRootElementProps } from "./utils"

export function HubspotFormSlice({ slice, otherProps, padding = true }) {
  const {
    region,
    portalId,
    formId,
    showColorfulBackground,
    headline,
    description,
    disclaimer,
  } = slice

  const hsRef = useRef();

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js-eu1.hsforms.net/forms/v2.js"
    document.body.appendChild(script)

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        hsRef.current = true;
        // @TS-ignore
        window.hbspt.forms.create({
          region,
          portalId,
          formId,
          target: "#hubspotForm",
        })
      }
    })
  }, [])

  useEffect(()=> {
    if (hsRef.current) {
      window.hbspt.forms.create({
        region,
        portalId,
        formId,
        target: "#hubspotForm",
      })
    }
  });

  const Root = styled.section`
    position: relative;
    z-index: 0;
    display: grid;
    background-color: ${({ theme }) => theme.backgroundColor};
    padding: ${padding || showColorfulBackground
      ? SPACE_1XL + "px 0 " + SPACE_3XL + "px 0"
      : "0"};

    ${breakpoints({
      SM: css`
        /* background-color: ${COLOR_GRAY_100}; */
      `,
      MD: css``,
      LG: css``,
      XL: css``,
      MAX: css``,
    })}
  `

  return (
    <ThemeProvider themeName="stone">
      <Root
        {...getSliceRootElementProps(slice, otherProps, {
          sliceBackgroundVariant: "stone",
        })}
      >
        <Background>
          {showColorfulBackground && (
            <>
              <RedCircle />
              <BlueCircle />
              <GreenRectangle />
            </>
          )}
        </Background>
        <Container>
          {headline && description && (
            <Section>
              <Headline type="tertiary">{headline.headline}</Headline>
              <Description data={description} keepTopLevelParagraph={false} />
            </Section>
          )}
          <Section>
            <HubspotForm id="hubspotForm"></HubspotForm>
          </Section>
          {disclaimer && (
            <Section>
              <Disclaimer data={disclaimer} keepTopLevelParagraph={false} />
            </Section>
          )}
        </Container>
      </Root>
    </ThemeProvider>
  )
}

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  overflow: hidden;

  ${breakpoints({
    SM: css`
      display: none;
    `,
  })}
`

const RedCircle = styled.div`
  position: absolute;
  top: 20vh;
  left: calc(13vw - ${SPACE_2XL}px);
  width: 21vw;
  height: 21vw;
  border-radius: 50%;
  background-color: ${COLOR_CORAL};
`

const BlueCircle = styled.div`
  position: absolute;
  top: 60vh;
  right: calc(15vw - ${SPACE_2XL}px);
  width: 13vw;
  height: 13vw;
  border-radius: 50%;
  background-color: ${COLOR_BLUE};
`

const GreenRectangle = styled.div`
  position: absolute;
  top: 55vh;
  left: calc(20vw - ${SPACE_2XL}px);
  right: calc(10vw - ${SPACE_2XL}px);
  height: 14vw;
  background-color: ${COLOR_GREEN};
  transform-origin: center;
  transform: rotate(-13.65deg);
`

const Container = styled.div`
  max-width: ${BREAKPOINT_SM}px;
  width: 100%;
  margin: auto;

  ${breakpoints({ SM: css`` })}
`

const Section = styled.section`
  border-radius: 8px;
  padding: ${SPACE_L}px ${SPACE_L}px ${SPACE_1XL}px;
  background-color: ${COLOR_GRAY_100};

  & ~ & {
    margin-top: ${SPACE_XL}px;

    ${breakpoints({
      SM: css`
        margin-top: 0;
      `,
    })}
  }

  ${breakpoints({
    SM: css`
      background-color: transparent;
    `,
  })}
`

const Headline = styled(Text)`
  margin-bottom: ${SPACE_XL}px;
`

const Description = styled(RichText)`
  ${textConfigToCSS(TEXT_TOKEN_COPY_BREAKPOINT_MD)}
`

const Disclaimer = styled(RichText)`
  h5 {
    padding: 0;
  }
  h6 {
    padding: 0;
    font-weight: normal;
    line-height: 16px;
  }
`

const HubspotForm = styled.div`
  max-width: ${BREAKPOINT_SM}px;
  width: 100%;
  margin: auto;

  ${breakpoints({ SM: css`` })}
`
