import { getURLHashForEntry } from "../../anchorLinks"
import { THEME_NAMES } from "../../styles"

export function getSliceRootElementProps(
  slice,
  otherProps,
  { sliceBackgroundVariant = defaultDetermineBackgroundVariant(slice) } = {}
) {
  return {
    id: getURLHashForEntry(slice),
    ...(sliceBackgroundVariant && {
      "data-slice-background-variant": sliceBackgroundVariant,
    }),
    ...otherProps,
  }
}

function defaultDetermineBackgroundVariant({ theme }) {
  return theme ? theme : "unknown"
}

export const SLICE_BACKGROUND_VARIANTS = THEME_NAMES
