import React from "react"

import { Modal } from "./Modal"
import { RichText } from "./RichText"

export function ContentfulModal({ headline, content, ...otherProps }) {
  return (
    <Modal headline={headline.headline} {...otherProps}>
      {content && <RichText data={content} />}
    </Modal>
  )
}
